module.exports = {
    get: (url, method) => {
        return ajax(url, method || 'GET');
    },
};

function ajax(url, method) {
    return fetch(url, { method: method }).then((response) => {
        if (response.status == 200) {
            return response.text();
        } else if (response.status == 204) {
            return '';
        }

        return null;
    });
}
